import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '主页',
    component: () => import( '../views/work-report.vue'),
  },
  {
      path: '/report-info',
      name:'报工',
      component: () => import( '../views/report-info.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
